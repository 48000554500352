<template>
  <div class="answer-content">
    <div class="main-wrapper-content">
      <StoreSetPageDetail :detail-data="detailData" v-if="Object.keys(detailData).length > 0 && detailData.module_id === 11" />
      <CustomPageDetail :detail-data="detailData" v-if="Object.keys(detailData).length > 0 && detailData.module_id !== 11" />
    </div>
  </div>
</template>

<script>
import {sactionExamDetail} from '@/utils/apis'
import CustomPageDetail from '@/components/student/train/CustomPageDetail.vue'
import StoreSetPageDetail from '@/components/student/train/StoreSetPageDetail.vue'
export default {
  name: "StoreDecorate",
  components:{
    CustomPageDetail,
    StoreSetPageDetail
  },
  data(){
    return {
      stuId:this.$route.query.id || null,
      examId:this.$route.query.examId || null,
      detailData:{},
    }
  },
  mounted() {
    this.getSactionExamDetail()
  },
  methods:{
    getSactionExamDetail(){
      let params = {
        exam_id:this.examId,
        student_id:this.stuId
      }
      sactionExamDetail(params).then((res)=>{
        this.detailData = res.data;
      }).catch((err)=>{
        console.log('err',err)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.answer-content{
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 20px;
  .main-wrapper-content{
    flex: 1;
    height: 1%;
    display: flex;
    flex-direction: column;
  }
}
</style>